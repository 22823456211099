<!--
 * @Author: 智客云网络科技
 * @Date: 2022-01-19 23:12:05
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-27 22:25:56
 * @Description: 生成账单
 * @FilePath: \src\views\App\Bill\BillManage.vue
-->

<template>
  <div class="vue-box">
    <!-- 文件上传组件 -->
    <el-row
      v-if="shou_upload"
      type="flex"
      justify="center"
      align="middle"
      style="height: 95%"
    >
      <el-col :span="24">
        <el-upload
          class="upload"
          drag
          action=""
          name="file"
          :http-request="_FileUpload"
          multiple
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传.xlsx/.xls文件，且不超过10MB
          </div>
        </el-upload>
      </el-col>
    </el-row>

    <!-- 账单显示组件 -->
    <el-row v-if="show_bill" :gutter="8">
      <el-col :span="24" style="margin-bottom: 5px">
        <p>快捷操作：</p>
        <el-button
          size="mini"
          type="warning"
          style="margin-left: 15px"
          @click="_PushBill(bill_data)"
          >全部推送</el-button
        >
      </el-col>

      <el-col
        :span="4"
        style="margin-bottom: 5px"
        v-for="(item, index) in bill_data"
        :key="index"
      >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-size: 16px; letter-spacing: 1px">
              {{ item.name }}
            </span>
            <!-- <el-button
              size="mini"
              type="primary"
              style="float: right; padding: 5px"
              @click="_CopyBill(item)"
            >
              复制账单
            </el-button> -->
            <el-button
              size="mini"
              type="success"
              icon="el-icon-s-promotion"
              style="float: right; padding: 5px; margin-right: 5px"
              @click="_PushBill([item])"
            >
              推送账单
            </el-button>
          </div>
          <div class="bill-con">
            <div class="bill-head">
              飞鱼国际-运费结算单<br />
              <!-- 线路:中=>韩<br /> -->
              客户姓名:{{ item.name }}<br />
              结算日期:{{ thisTime }}<br />
            </div>
            <div v-for="(bill_item, index) in item.items" :key="index">
              <br />
              -- 包裹{{ bill_item.index }}-{{ bill_item.channel }}<br />
              重量：{{ bill_item.weight }}kg<br />
              运费：{{ bill_item.basic_cost }}元<br />

              <span v-if="bill_item.item_scc_cost !== 0">
                简易清关费：{{ bill_item.item_scc_cost }}元
                <br />
              </span>

              <span v-if="bill_item.item_pc_cost !== 0">
                护照清关费：{{ bill_item.item_pc_cost }}元
                <br />
              </span>

              <span v-if="bill_item.item_material_cost !== 0">
                包装材料费：{{ bill_item.item_material_cost }}元
                <br />
              </span>

              <span v-if="bill_item.item_reinforcement_cost !== 0">
                加固打包费：{{ bill_item.item_reinforcement_cost }}元
                <br />
              </span>

              <span v-if="bill_item.volume_cost !== 0">
                体积费：{{ bill_item.volume_cost }}元
                <br />
              </span>
            </div>

            <div class="bill-tail">
              <div v-if="item.add_cost !== 0">
                -- 其他费用
                <br />

                <!-- <span v-if="item.scc_cost !== 0">
                简易清关费：{{ item.scc_cost }}元
                <br />
                </span> -->

                <!-- <span v-if="item.pc_cost !== 0">
                护照清关费：{{ item.pc_cost }}元
                <br />
                </span> -->

                <!-- <span v-if="item.material_cost !== 0">
                包装材料费：{{ item.material_cost }}元
                <br />
                </span> -->

                <!-- <span v-if="item.reinforcement_cost !== 0">
                加固打包费：{{ item.reinforcement_cost }}元
                <br />
                </span> -->

                <!-- <span v-if="item.volume_cost !== 0">
                体积费：{{ item.volume_cost }}元
                <br />
                </span> -->

                <span>
                  附加费：{{ item.add_cost }}元
                  <br />
                </span>
              </div>

              <br />
              -- 费用合计

              <br />
              合计(CNY)：{{ item.all_cost }}元

              <br />
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <div style="height: 25px"></div>
  </div>
</template>

<script>
import { getBill, pushBill } from "./api"; //页面专有接口
import { uploadFile } from "@/api/api"; //公共api 图像文件上传接口
export default {
  name: "BillManage",
  data() {
    return {
      loading: null, //加载
      shou_upload: true, //显示文件上传框
      show_bill: false, //显示账单
      file_name: "", //已上传的文件名称
      thisTime: "", //当前日期
      bill_data: [], //账单数据
    };
  },
  watch: {
    //监听文件上传拉取账单
    file_name() {
      if (this.file_name !== "") {
        this._GetBill();
      }
    },
  },
  methods: {
    //获取账单
    _GetBill() {
      this._OpenLoading(); //启动加载
      getBill({ file_name: this.file_name })
        .then((res) => {
          this._CloseLoading(); //关闭加载
          this.bill_data = res.data;
          this.shou_upload = false; //关闭文件上传
          setTimeout(() => {
            this.show_bill = true;
          }, 500);
        })
        .catch((err) => {
          this._CloseLoading(); //关闭加载
          this.$utils.error(err.err_msg);
        });
    },

    //文件上传
    _FileUpload(file) {
      let formData = new FormData(); // 声明一个FormData对象
      formData.append(file.filename, file.file, file.file.name);
      uploadFile(formData)
        .then((res) => {
          this.file_name = res.data;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取当前日期
    _GetTime() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      this.thisTime = year + "-" + month + "-" + day;
    },

    //复制账单
    _CopyBill(bill) {
      let bill_html = this._Bill2Html(bill);
      this.$utils.copyText(bill_html);
      this.$utils.success("[ " + bill.name + " ]的账单已复制到剪贴板！");
    },

    //账单转文本格式
    _Bill2Html(bill_config) {
      var html = "";

      const item = bill_config;
      let customer_name = item.name; //客户姓名
      let time = this.$utils.forDate(1); //账单生成日期
      let scc_cost = item.scc_cost; //简易清关费
      let pc_cost = item.pc_cost; //护照清关费
      let material_cost = item.material_cost; //包装材料
      let reinforcement_cost = item.reinforcement_cost; //加固打包费
      let add_cost = item.add_cost; //附加费
      let volume_cost = item.volume_cost; //体积费
      let all_cost = item.all_cost; //总费用
      let bill_items = item.items; //包裹信息

      //账单头部
      var htm_head = "";
      htm_head += "飞鱼国际-运费结算单\r\n";
      // htm_head .= "线路:中=>韩\r\n";
      htm_head += "客户姓名:" + customer_name + "\r\n";
      htm_head += "结算日期:" + time + "\r\n";
      htm_head += "\r\n";

      //账单尾部
      let htm_tail_add = 0; //累计次数
      var htm_tail = "";
      htm_tail += "-- 其他费用\r\n";

      if (scc_cost != 0) {
        htm_tail += "简易清关费：" + scc_cost + "元\r\n";
        htm_tail_add++;
      }

      if (pc_cost != 0) {
        htm_tail += "护照清关费：" + pc_cost + "元\r\n";
        htm_tail_add++;
      }

      if (material_cost != 0) {
        htm_tail += "包装材料费：" + material_cost + "元\r\n";
        htm_tail_add++;
      }

      if (reinforcement_cost != 0) {
        htm_tail += "加固打包费：" + reinforcement_cost + "元\r\n";
        htm_tail_add++;
      }

      if (volume_cost != 0) {
        htm_tail += "体积费：" + volume_cost + "元\r\n";
        htm_tail_add++;
      }

      if (add_cost != 0) {
        htm_tail += "附加费：" + add_cost + "元\r\n";
        htm_tail_add++;
      }

      if (htm_tail_add === 0) {
        htm_tail = "";
      } else {
        htm_tail += "\r\n";
      }

      htm_tail += "-- 费用合计\r\n";
      htm_tail += "合计(CNY)：" + all_cost + "元\r\n";

      //创建账单体
      if (typeof bill_items !== "object") {
        return this.$utils.msg("账单生成失败，请稍后再试！");
      }

      var htm_content = "";

      for (let bi = 0; bi < bill_items.length; bi++) {
        const bitem = bill_items[bi];

        let index = bitem["index"]; //包裹序号
        let channel = bitem["channel"]; //渠道
        let weight = bitem["weight"]; //重量
        let basic_cost = bitem["basic_cost"]; //运费

        htm_content += "-- 包裹" + index + "-" + channel + "\r\n";
        htm_content += "重量：" + weight + "kg\r\n";
        htm_content += "运费：" + basic_cost + "元\r\n";
        htm_content += "\r\n";
      }

      html += htm_head + htm_content + htm_tail;
      return html;
    },

    //推送账单到群聊
    _PushBill(bill_config) {
      pushBill({ bill_config: JSON.stringify(bill_config) })
        .then((res) => {
          this.$utils.success(res.err_msg);
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //开启加载框
    _OpenLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "账单生成中，请稍后……",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.8)",
      });
    },

    //关闭加载框
    _CloseLoading() {
      setTimeout(() => {
        this.loading.close();
      }, 500);
    },
  },
  mounted() {
    this._GetTime();
  },
};
</script>
<style>
.upload {
  height: 100%;
  margin: auto;
  text-align: center;
}

.bill-con {
  height: auto;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  font-family: Tahoma, Helvetica, Arial, "宋体", sans-serif;
}

.el-card__body {
  overflow-y: auto;
  height: 360px;
}

/*滚动条的宽度*/
.el-card__body::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

/*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/
.el-card__body::-webkit-scrollbar-track {
  width: 6px;
  background-color: #f1f1f1;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  border-radius: 1em;
}

/*滚动条的设置*/
.el-card__body::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  background-clip: padding-box;
  min-height: 15px;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  border-radius: 1em;
}
</style>
