/*
 * @Author: 智客云网络科技
 * @Date: 2021-11-01 15:00:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-23 11:07:41
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\App\Bill\api.js
 */

import http from "@/api";

/**************************** 账单操作 ****************************/

// 获取账单
export const getBill = (params) => http.get("/v1/Bill/GetBill", params);

// 推送账单
export const pushBill = (params) => http.post("/v1/Bill/PushBill", params);
